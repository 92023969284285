import {
  Ref,
  computed,
  useContext,
  readonly,
  ssrRef,
} from '@nuxtjs/composition-api'
import { useConfig } from '../useConfig'
import { Product, ProductAvailability } from '~/types/product'
import { ReactiveValue } from '~/types/common'
import { checkOrderable, getMaxQuantity } from '~/lib/api/deserializers/product'

export const MAX_QUANTITY = 100000

export enum ProductEvents {
  Search = 'product/search',
  ViewList = 'product/viewList',
  Click = 'product/click',
  ViewDetails = 'product/viewDetails',
}

const SUSPENDED_PREFERRED_SUPPLIER_STATE = 'end of life'

export const useProduct = (
  productRef: ReactiveValue<Product | null>,
  cartQuantityRef?: Ref<number>
  // inputQuantityRef?: Ref<number>
) => {
  // inputQuantityRef ??= cartQuantityRef
  const { config } = useConfig()

  // Availability
  const isOrderable = computed(() =>
    productRef.value ? checkOrderable(productRef.value) : false
  )
  const webStock = computed(() => getStock(config.value?.webStoreId))
  const availability = computed(() => {
    if (!isOrderable.value) return ProductAvailability.NotOrderable

    if (productRef.value?.inStock) return ProductAvailability.InStock

    return ProductAvailability.OutOfStock
  })
  const deliveryStock = computed(() => webStock.value)
  const deliveryAvailability = computed(() =>
    deliveryStock.value > 0
      ? ProductAvailability.InStock
      : ProductAvailability.OutOfStock
  )
  const deliveryAvailabilityInc = computed(() => {
    return deliveryStock.value !== 0 &&
      deliveryStock.value >= (cartQuantityRef?.value ?? 0)
      ? ProductAvailability.InStock
      : ProductAvailability.OutOfStock
  })
  const customText = computed(() => {
    return productRef.value?.customField1
  })
  // const deliveryStock = computed(() => webStock.value)
  // const pickupStock = computed(() => webStock.value)
  const maxQuantity = computed(() =>
    productRef.value
      ? getMaxQuantity(
          productRef.value.stockQuantity,
          productRef.value.maxInCart
        )
      : 0
  )
  const maxQuantityInc = computed(() => {
    const quantityInc = maxQuantity.value - (cartQuantityRef?.value ?? 0)
    return quantityInc > 0 ? quantityInc : 0
  })

  const isSuspendedPreferredSupplier = computed(() => {
    return (
      !!productRef.value?.preferredSupplier &&
      productRef.value.preferredSupplier.state.toLowerCase() ===
        SUSPENDED_PREFERRED_SUPPLIER_STATE
    )
  })

  const getStock = (storeId?: number) => {
    if (!productRef.value) return 0
    const stock = productRef.value.stock
    if (!storeId || !stock) return 0

    const store = stock.find((st) => st.storeId === storeId)
    if (!store) return 0

    return store.quantity > 0 ? store.quantity : 0
  }

  return {
    isOrderable,
    maxQuantity,
    maxQuantityInc,
    webStock,
    availability,
    deliveryAvailability,
    deliveryAvailabilityInc,
    isSuspendedPreferredSupplier,
    customText,
    // deliveryStock,
    // pickupStock,

    getStock,
  }
}

export const useProductStockRefresh = (
  productParam: Ref<Product[]> | Ref<Product | null>
) => {
  const products = computed(() => {
    const asProducts = productParam.value as Product[]
    if (typeof asProducts?.length === 'undefined') {
      if (productParam.value === null) {
        return []
      } else {
        return [productParam.value as Product]
      }
    } else {
      return asProducts
    }
  })

  const { app } = useContext()

  const productLoadedFromServer = ssrRef<boolean>(false)

  const onProductsLoaded = () => {
    if (process.server) {
      productLoadedFromServer.value = true
    }
  }

  const refreshStocks = async (options?: any) => {
    options = Object.assign(
      {
        forceRefresh: false,
      },
      options
    )

    if (!products.value?.length) return
    if (!options.forceRefresh && !productLoadedFromServer.value) return

    const stockMap = await app.$api.product.getStocks(
      products.value.map((product) => product.id)
    )
    if (Object.keys(stockMap)) {
      products.value.forEach((product) => {
        const stockRefresh = stockMap[product.id.toString()]
        if (stockRefresh) {
          product.stock = stockRefresh.stock
          Object.assign(product, stockRefresh.availibility)
        }
      })
    }
  }

  return {
    productLoadedFromServer: readonly(productLoadedFromServer),

    onProductsLoaded,
    refreshStocks,
  }
}
